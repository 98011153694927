<!--
 * @Description:用户端-底部我的-反馈建议-反馈详情 feedBackDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-24 12:01:48
 * @LastEditors: LinFeng
-->
<template>
  <div class='body'>
    <van-nav-bar title="反馈详情"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row class="lineone">
        <van-col span="6"
                 style="margin-left:12px">{{feedBackDetails.feedbackTypeDesc}}
        </van-col>
        <van-col span="8">
          <van-row class="button"
                   v-if="feedBackDetails.feedbackStatusCode==1"
                   style="color: #19A9FC;border-color:#19A9FC">{{feedBackDetails.feedbackStatusDesc}}</van-row>
          <van-row class="button"
                   v-else>{{feedBackDetails.feedbackStatusDesc}}</van-row>

        </van-col>

        <van-col span="9"
                 style="font-size:12px;color:#999999">{{feedBackDetails.createTime}}</van-col>
      </van-row>
      <van-row class="linetwo">
        <van-row class="textone">反馈描述 ：
        </van-row>
        <van-row class="texttwo">
          {{feedBackDetails.content}}</van-row>
        <van-row class="textone">联系方式 ：
        </van-row>
        <van-row class="texttwo">
          {{feedBackDetails.concat}}</van-row>

        <div v-if="feedBackDetails.feedbackStatusCode==2">
          <van-row class="textone">处理时间 ：
          </van-row>
          <van-row class="texttwo">
            {{feedBackDetails.handlerTime}}</van-row>

          <van-row class="textone">处理结果 ：
          </van-row>
          <van-row class="texttwo">
            {{feedBackDetails.handleResult}}</van-row>
        </div>
        <div v-else></div>
        <van-row class="textone">照片展示 ：
        </van-row>
        <van-row type="flex"
                 justify="space-between">

          <van-image width="157"
                     height="210"
                     @click="getImg()"
                     :src="$imgBaseUrl + feedBackDetails.picUrl" />
        </van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { ImagePreview } from 'vant'
export default {
  // import引入的组件需要注入到对象中才能使用

  components: {},
  data () {
    // 这里存放数据
    return {
      // 反馈详情
      feedBackDetails: [],
      pic: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.feedBackDetails = this.$route.query.feedBackDetails
    this.pic = this.$route.query.feedBackDetails.picUrl
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 顶部返回
    handleClickTopBack () { this.$router.go(-1) },

    getImg () {
      ImagePreview({
        images: [this.$imgBaseUrl + this.pic],
        showIndex: true,
        loop: false

      })
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.textone {
  font-size: 15px;
  font-weight: 400;
  color: #333333;
}
.texttwo {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
}
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    width: 93.6%;
    height: calc(100% - 46px - 12px);
    margin: 12px 3.2% 0 3.2%;
    background-color: #ffffff;
    border-radius: 5px;
    .lineone {
      border-bottom: 1px solid #ebebeb;
      height: 41px;
      line-height: 41px;
      .button {
        border: 1px solid #999999;
        border-radius: 15px;
        height: 17px;
        line-height: 17px;
        width: 44px;
        font-size: 10px;
        margin-top: 11px;
        text-align: center;
        color: #999999;
      }
    }
    .linetwo .van-row {
      padding-top: 12px;
      margin: 0 12px 0 12px;
    }
  }
}
</style>
